import React from 'react';

const Años = () => {
  return ( 
    <>
      <section className='home__anios'>
        <div className='home__anios-content-img'>
          <img src="./../assets/img/home/anios/306130781_5670765572991129_2960615159512423042_n.jpg" className='home__anios-img' title="Iesap - Licenciamiento" alt="Iesap - Licenciamiento" />
        </div>
      </section>
    </>
  );
}
 
export default Años;

